import React, { useEffect, useRef } from 'react';
import { Product } from 'types/product';
import classNames from 'classnames';
import ProductTile from 'components/commercetools-ui/organisms/product/product-tile/index';
import { sendGTMEvent } from 'components/headless/GTMSnippet';
import { USER_CATEGORY } from 'helpers/constants/seoConstants';
import { useLocale } from 'helpers/hooks/useLocale';
import { getVariantLowerPrice, hasMultiplePromoMessages } from 'helpers/productHelpers';
import { useAccount } from 'frontastic';
import ListProductsTableView from './ListProductsTableView';
import { TNikonProduct } from '../../../../../../../types/TNikonProduct';
import ProductListItem from '../../../product-list-item';
import ProductTableHeader from '../../../product-table/Header/Header';
import { useProductList } from '../../context';
import useDragToScroll from '../../hooks/useDragToScroll';

interface Props {
  products: Product[];
}

const List: React.FC<Props> = ({ products }) => {
  const { view } = useProductList();
  const containerRef = useRef<HTMLDivElement>(null);
  const { account } = useAccount();
  const { isUsaStore, isCanadaStore } = useLocale();
  const { handleMouseDown, handleMouseUp, handleMouseMove, handleMouseLeave, showSwipeIcon, hideSwipeIcon } =
    useDragToScroll(containerRef);

  const gridStyle = {
    grid: 'gap-16 lg:gap-24 md:grid-cols-2 lg:grid-cols-3',
    list: 'gap-16 lg:gap-24',
    table: 'min-w-[670px] relative',
  };

  const getProductGTMEventData = (product: Product, index = 0) => {
    const variant = product.variants.reduce(
      (acc, curr) =>
        curr.discountedPrice && (!acc?.discountedPrice || acc.discountedPrice > curr.discountedPrice) ? curr : acc,
      product.variants[0],
    );

    const selectedVariant = variant || product.variants[0];

    // INFO: Price logic copied from ProductTile Description Price component
    const variantLowerPrice = getVariantLowerPrice((product as unknown as TNikonProduct).variants);
    const haveMultipleVariants = product.variants.length > 1;
    const variantDiscountedPrice = variantLowerPrice?.discountedPrice;
    const customBasePrice = variantLowerPrice?.price?.custom?.fields?.basePrice;
    const price = customBasePrice || variantLowerPrice?.price;
    const discountedPrice = customBasePrice
      ? variantDiscountedPrice // This is when the product have both discounts configured, a discount price and a marketing copy discount
        ? variantDiscountedPrice
        : variantLowerPrice?.price
      : variantDiscountedPrice || undefined;
    const startingAtPrice = discountedPrice || price;

    const isShowDiscount = Boolean(discountedPrice && (isUsaStore || isCanadaStore) && !haveMultipleVariants);
    const isShowMultiplePromoMessage = Boolean(
      (isUsaStore || isCanadaStore) && hasMultiplePromoMessages((product as unknown as TNikonProduct).variants),
    );
    const productFinalPrice = isShowDiscount
      ? discountedPrice
      : haveMultipleVariants
      ? startingAtPrice
      : !isShowMultiplePromoMessage
      ? price
      : undefined;

    return {
      item_id: product?.key,
      item_name: product.name,
      item_category: product.categories?.map((category) => category.name).join(', '),
      item_variant: selectedVariant?.sku || product.key,
      index,
      price: (productFinalPrice?.centAmount || 0) / 100,
      item_category5: selectedVariant?.attributes?.title || null,
    };
  };

  useEffect(() => {
    if (window !== undefined) {
      sendGTMEvent({
        event: 'view_item_list',
        hit_time_stamp: Date.now(),
        userId: account?.accountId,
        userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
        ecommerce: {
          items: products.map((product, i) => getProductGTMEventData(product, i)),
        },
      });
    }
  }, [products]);

  return (
    <div
      className="overflow-x-auto"
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onScroll={hideSwipeIcon}
    >
      <div className={classNames('grid grid-cols-1', gridStyle[view])}>
        {view === 'table' && (
          <>
            {showSwipeIcon && (
              <div className="absolute left-[50%] top-40 rotate-90 md:left-225 lg:hidden">
                <div className="h-60 w-60 animate-bounce rounded-full border bg-slate-100 p-10 opacity-95 shadow-lg">
                  <img alt="swipe-icon" className="-rotate-90" src="/icons/swipe.svg" />
                </div>
              </div>
            )}
            <ProductTableHeader />
            <ListProductsTableView products={products} getProductGTMEventData={getProductGTMEventData} />
          </>
        )}

        {view !== 'table' &&
          products.map((product, i) => {
            if (view === 'grid') {
              return (
                <ProductTile
                  key={product.productId}
                  product={product as unknown as TNikonProduct}
                  getProductGTMEventData={getProductGTMEventData}
                  index={i}
                />
              );
            }

            if (view === 'list') {
              return (
                <ProductListItem
                  key={product.productId}
                  product={product as unknown as TNikonProduct}
                  getProductGTMEventData={getProductGTMEventData}
                  index={i}
                />
              );
            }
          })}
      </div>
    </div>
  );
};

export default List;
